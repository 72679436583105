body {
    background-color: #000; /* Set the overall page background color to black */
    margin: 0; /* Remove default margin to ensure full coverage of the background */
    overflow: hidden; /* Prevent horizontal scrollbar due to negative margins or padding */
  }
  
  .auth-widget {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background-color: #fff; /* Set background color of the widget to white */
    border: 1px solid #333; /* Set border color to a darker shade */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adjust shadow color */
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .auth-icon {
    width: 50px; /* Adjust the size of the Auth0 icon */
    height: 50px;
    margin-bottom: 20px;
  }
  
  h2 {
    color: #333; /* Set text color to a darker shade */
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  